<template>
  <div class="voting">
    <b-loading :is-full-page="true" v-model="state.isLoading" :can-cancel="false"></b-loading>
    <b-button
      size="is-large"
      icon-left="arrow-right"
      v-for="config in state.voteConfigs"
      :key="config.tag"
      @click="$router.push(`voting${config.tag}`)" expanded
    >
      {{config.title}}
    </b-button>
  </div>
</template>

<script>
import { reactive } from '@vue/composition-api';
import { DialogProgrammatic as Dialog } from 'buefy';
import * as _ from 'lodash';
import Parse from 'parse';
import { getParseObjectData } from '../libs/parse-util';
export default {
  setup (props, { root: vue }) {
    let state = reactive({
      isLoading: true,
      voteConfigs: [],
    });
    // setup with async ops
    (async () => {
      const config = await Parse.Config.get();
      const activeVoteTags = config.get('activeVoteTags');
      const voteConfigs = await new Parse.Query('VoteConfig').containedIn('tag', activeVoteTags).find();
      state.voteConfigs = getParseObjectData(voteConfigs);
      if (!state.voteConfigs.length) {
        Dialog.alert({
          message: '現時沒有投票。',
          hasIcon: false,
          ariaModal: true
        });
        return;
      }
      if (state.voteConfigs.length === 1) {
        vue.$router.push(`/voting${state.voteConfigs[0].tag}`);
      }
      state.isLoading = false;
    })();
    return { state };
  }
};
</script>

<style lang="scss" scoped>
</style>
