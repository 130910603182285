<template>
  <div class="voting-2022chin">
    <b-loading :is-full-page="true" v-model="state.isLoading" :can-cancel="false"></b-loading>
    <div v-if="state.state === 'intro'" v-show="!state.isLoading">
      <img style="width: 100%; max-width: 1000px" src="/img/2022/PPC2022youtube.png">
      <h1 class="title has-text-centered">{{state.title}}</h1>
      <div class="voteDescription" v-html="state.description"></div>
      <b-button type="is-success is-light" @click="state.state = 'vote'">開始投票</b-button>
    </div>
    <div v-if="state.state === 'vote'">
      <div class="is-flex is-justify-content-space-around">
        <div class="box form">
          <h1 class="title has-text-centered">{{state.title}}</h1>
          <label class="label">投票選擇（最多五款桌遊）</label>
          <div class="displayGameList columns is-flex-wrap-wrap">
            <div class="displayGame column is-full-mobile is-full-tablet is-half-desktop" v-for="game of displayingGames" :key="game.gameId">
              <div class="media">
                <b-checkbox v-model="voteForm.chosenGameIds" :native-value="game.gameId"></b-checkbox>
                <div class="media-left gameThumbnail" :style="{'backgroundImage': `url('${game.thumbnail}')`}"></div>
                <div class="media-content">
                  {{ game.name }}
                  <br>
                </div>
              </div>
            </div>
          </div>
          <!-- <b-field v-if="voteForm.chosenGameIds.length < 5">
            <b-autocomplete
              v-model="state.gameNameSearchString"
              :data="filteredGames"
              placeholder="搜尋其他桌遊（桌遊名稱）"
              icon="magnify"
              field="name"
              open-on-focus
              @select="selectGame">
              <template slot-scope="{option: game}">
                <div class="media">
                  <div class="media-left gameThumbnail" :style="{'backgroundImage': `url('${game.thumbnail}')`}"></div>
                  <div class="media-content">
                    {{ game.name }}
                    <br>
                    <small>
                      Rank: {{ game.rank }},
                      Rating: {{ game.rating }}
                    </small>
                  </div>
                </div>
              </template>
            </b-autocomplete>
          </b-field> -->
          <b-field label="加入其他遊戲">
            <b-input v-model="state.additionalVoteInput"></b-input>
            <b-button type="is-success is-light" @click="addAdditionalVote()">加入</b-button>
          </b-field>
          <ul>
            <li class="additionalVote" v-for="game of voteForm.additionalVotes" :key="game">
              <b-checkbox disabled :value="true"></b-checkbox>
              {{game}}
              <b-button type="is-danger" size="is-small" icon-right="delete" @click="deleteAdditionalVote(game)" />
            </li>
          </ul>
          <b-field label="身處地區">
            <b-radio v-model="voteForm.location" native-value="hk">香港</b-radio>
            <b-radio v-model="voteForm.location" native-value="tw">台灣</b-radio>
            <b-radio v-model="voteForm.location" native-value="other">其他</b-radio>
          </b-field>
          <b-field label="是否已加入桌遊港Patreon?">
            <b-radio v-model="voteForm.patreon" native-value="false">否</b-radio>
            <b-radio v-model="voteForm.patreon" native-value="true">是</b-radio>
          </b-field>
          <b-field label="電郵地址 (如已加入 桌遊港Patreon 請填入 Patreon 帳戶電郵)">
            <b-input type="email" v-model="voteForm.email"></b-input>
          </b-field>
          <b-field label="電話號碼尾4個數字：(電話號碼只作領獎時核實身份之用；此欄可以不填，則視作放棄參加獎品活動)">
            <b-input v-model="voteForm.phone"></b-input>
          </b-field>
          <!-- <p class="is-small">投票完成後會彈出專屬投票圖，參加獎品活動的朋友，敬請儲存及於社交媒體分享是次投票！</p> -->
          <div class="is-flex is-justify-content-flex-end">
            <b-button type="is-success is-light" @click="submit()">確認投票</b-button>
          </div>
        </div> <!-- end of div.box -->
      </div>
    </div>
    <VoteImg v-if="state.isSubmitted" :options="voteImageOptions"></VoteImg>
  </div>
</template>

<script>
import { reactive, computed } from '@vue/composition-api';
import { DialogProgrammatic as Dialog } from 'buefy';
import { get } from 'lodash';
import Parse from 'parse';
import { getParseObjectData } from '../libs/parse-util';
import { handleError } from '../libs/vp-helper';
import VoteImg from '../component/voteimg.vue';
export default {
  components: {
    VoteImg,
  },
  setup () {
    const state = reactive({
      isLoading: true,
      state: 'intro',
      games: [],
      gameNameSearchString: '',
      displayingGameIds: [],
      title: '',
      description: '',
      additionalVoteInput: '',
      chosenGameCountMax: 5,
      isSubmitted: false,
    });
    const voteForm = reactive({
      email: '',
      patreon: 'false',
      location: 'hk',
      chosenGameIds: [],
      additionalVotes: [],
    });
    // TODO: read from server / params
    const voteTag = '2022chin';
    const voteImageOptions = reactive({
      bgPath: 'img/2022/2022CN.png',
      gameImgWidth: 300,
      gameImgHeight: 300,
      imagePositions: [
        {
          id: '',
          top: '412px',
          left: '390px',
          src: '',
        },
        {
          id: '',
          top: '246px',
          left: '45px',
          src: '',
        },
        {
          id: '',
          top: '246px',
          left: '735px',
          src: '',
        },
        {
          id: '',
          top: '578px',
          left: '45px',
          src: '',
        },
        {
          id: '',
          top: '578px',
          left: '735px',
          src: '',
        },
      ],
      completeMessage: '投票完成！非常感謝你的參與！<br>敬請留意桌遊港頻道的投票及獎品活動結果！<br>如仍未參與另外幾項投票，請立即行動！',
    });
    let voteConfig;
    const displayingGames = computed(() => {
      // return state.games.filter(g => state.displayingGameIds.includes(g.gameId) || voteForm.chosenGameIds.includes(g.gameId));
      return state.games;
    });
    function selectGame (game) {
      if (!game) return;
      if (!voteForm.chosenGameIds.includes(game.gameId)) {
        voteForm.chosenGameIds.push(game.gameId);
      }
      setTimeout(() => {
        state.gameNameSearchString = '';
      }, 100);
    }
    const filteredGames = computed(() => {
      if (!state.gameNameSearchString) return state.games;
      return state.games.filter(g => g.name.toLowerCase().startsWith(state.gameNameSearchString.toLowerCase()));
    });
    const addAdditionalVote = () => {
      if (!state.additionalVoteInput) return;
      const input = state.additionalVoteInput;
      state.additionalVoteInput = '';
      if (voteForm.additionalVotes.includes(input)) return;
      voteForm.additionalVotes.push(input);
    };
    const deleteAdditionalVote = (game) => {
      voteForm.additionalVotes = voteForm.additionalVotes.filter(v => v !== game);
    };
    async function submit () {
      // TODO:
      voteForm.patreon = voteForm.patreon === 'true';
      console.log(voteForm);
      state.isLoading = true;
      try {
        // TODO: validation
        if (!voteForm.email) throw new Error('請填上電郵地址');
        const voteCount = voteForm.chosenGameIds.length + voteForm.additionalVotes.length;
        if (!voteCount) throw new Error('請選擇遊戲');
        if (voteCount > state.chosenGameCountMax) throw new Error(`最多選擇${state.chosenGameCountMax}款遊戲`);
        await Parse.Cloud.run('vote', { tag: voteTag, data: voteForm });
        // Dialog.alert({
        //   message: '投票完成！非常感謝你的參與！<br>敬請留意桌遊港頻道的投票及獎品活動結果！<br>如仍未參與另外兩項投票，請立即行動！',
        //   hasIcon: false,
        //   ariaModal: true
        // });
        // this.$router.push('/');
        for (let i = 0; i < voteForm.chosenGameIds.length; ++i) {
          if (i >= voteImageOptions.imagePositions.length) break;
          const gameId = voteForm.chosenGameIds[i];
          const game = state.games.find(g => g.gameId === gameId);
          if (!game) continue;
          const pos = voteImageOptions.imagePositions[i];
          pos.id = `${gameId}`;
          pos.src = game.thumbnail;
        }
        state.isSubmitted = true;
      } catch (e) {
        handleError(e);
      }
      state.isLoading = false;
    };
    // setup with async ops
    (async () => {
      // get vote configs
      voteConfig = await new Parse.Query('VoteConfig').equalTo('tag', voteTag).first();
      state.displayingGameIds = voteConfig.get('displayingGameIds');
      state.title = voteConfig.get('title');
      state.description = voteConfig.get('description');
      state.chosenGameCountMax = voteConfig.get('chosenGameCountMax');
      if (!state.chosenGameCountMax) state.chosenGameCountMax = 5;
      // get game lists
      const gameObjs = await new Parse.Query('BgpChineseGame2022').limit(1000).find();
      state.games = getParseObjectData(gameObjs).map(game => {
        const fileName = get(game, 'thumbnail', 'notfound.png');
        const thumbnail = `/img/2022chin/${fileName}`;
        return {
          ...game,
          thumbnail,
        };
      });
      if (!state.description) {
        // jump to voting if there is no description
        state.state = 'vote';
      }
      state.isLoading = false;
    })().catch(handleError);
    return { state, voteForm, displayingGames, selectGame, filteredGames, addAdditionalVote, deleteAdditionalVote, submit, voteImageOptions };
  }
};
</script>

<style lang="scss" scoped>
.voteDescription {
  margin-bottom: 1rem;
}

.form {
  width: 36rem;

  .field {
    margin-bottom: 2rem;
  }
}

.displayGameList {
  margin-top: 1rem;
}

.displayGame {
  height: 3rem;
  overflow: hidden;
  padding: 0 0.75em;
}

.gameThumbnail {
  width: 42px;
  height: 42px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.additionalVote {
  margin-bottom: .5rem;
}
</style>
