<template>
  <div class="voteImg">
    <b-loading :is-full-page="false" v-model="state.isLoading">
    </b-loading>
    <div class="renderDiv" v-if="!state.isRendered">
    <!-- <div class="renderDiv"> -->
      <span id="render" class="render">
        <div v-for="pos in options.imagePositions" :key="pos.id" class="gameImg" :style="{top: pos.top, left: pos.left, width: options.gameImgWidth + 'px', height: options.gameImgHeight + 'px', backgroundImage: `url(${pos.src})`, backgroundSize: state.size}">
          <img @load="onImageLoad" @error="onImageLoad" :src="pos.src">
        </div>
        <img :src="options.bgPath">
      </span>
    </div>
    <div class="preview">
      <div class="wrapper">
        <div class="header">
          <span class="shareTitle">下載並分享你的投票</span>
          <b-button type="is-success is-light" @click="downloadURI">下載</b-button>
        </div>
        <center>
          <img class="previewImage" v-if="state.renderImage" :src="state.renderImage">
        </center>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.voteImg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 30;

  .preview {
    background-color: #b8b8b8cc;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: visible;
  }

  .renderDiv {
    position: absolute;
    width: 10000px;
    top: 0;
    left: 0;
    overflow: visible;
    // z-index: 100;

    .render {
      position: relative;
      left: 100%;
      display: inline-flex;

      .gameImg {
        position: absolute;
        overflow: hidden;
        border-radius: 40px;
        background-position: center;
        background-repeat: no-repeat;
        background-position: center;

        img {
          display: none;
        //   height: 100%;
        //   max-width: none;
        //   margin-left: 50%;
        //   transform: translateX(-50%);
        }
      }
    }
  }

  .wrapper {
    background-color: white;
    width: 60%;
    min-width: 360px;
    min-height: 250px;
    max-height: 100%;
    overflow: auto;
    border-radius: 10px;
    padding: 2rem;

    .header{
      display: flex;
      flex-direction: row;
      margin-bottom: .5rem;

      .shareTitle {
        flex-grow: 1;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }

    .previewImage{
      max-height: 600px;
    }
  }
}
</style>

<script>
import { reactive } from '@vue/composition-api';
import { DialogProgrammatic as Dialog } from 'buefy';
import html2canvas from 'html2canvas';
export default {
  name: 'VoteImg',
  // props: {
  //   options: {
  //     bgPath: String,
  //     gameImagePositions: Vector2[],
  //   }
  // },
  props: ['options'],
  setup ({ options }, { root: vue }) {
    const state = reactive({
      // isLoading: true,
      isLoading: true,
      isRendered: false,
      renderImage: '',
      size: 'cover', // TODO: cover or contain
    });
    var loadedImageCount = 0;
    var isCreateImageStarted = false;
    const createImage = async () => {
      if (isCreateImageStarted) return;
      isCreateImageStarted = true;
      const canvas = await html2canvas(document.querySelector('#render'));
      state.isRendered = true;
      state.renderImage = canvas.toDataURL();
      state.isLoading = false;
    };
    const onImageLoad = () => {
      // console.warn('onImageLoad');
      ++loadedImageCount;
      if (loadedImageCount >= options.imagePositions.length) {
        createImage();
      }
    };
    setTimeout(async () => {
      createImage();
    }, 30000);
    const downloadURI = () => {
      if (!state.renderImage) return;
      var link = document.createElement('a');
      link.download = 'bgp-vote';
      link.href = state.renderImage;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      Dialog.confirm({
        // title: '感謝參與',
        message: options.completeMessage,
        onConfirm: () => {
          vue.$router.push('/');
        }
      });
    };
    return { state, options: { ...options }, onImageLoad, downloadURI };
  },
};
</script>
