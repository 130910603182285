import { render, staticRenderFns } from "./Voting2023.vue?vue&type=template&id=2f5b472e&scoped=true&"
import script from "./Voting2023.vue?vue&type=script&lang=js&"
export * from "./Voting2023.vue?vue&type=script&lang=js&"
import style0 from "./Voting2023.vue?vue&type=style&index=0&id=2f5b472e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f5b472e",
  null
  
)

export default component.exports