<template>
  <div class="home">
    <h1>Login</h1>
    <div v-if="!state.user">
      <form @submit.stop.prevent="login">
        <input type="text" v-model="state.username">
        <input type="password" v-model="state.password">
        <button type="submit">login</button>
      </form>
    </div>
    <div v-else>
      <p>User: {{state.user.get('username')}}</p>
      <button @click="logout">logout</button>
    </div>
  </div>
</template>

<script>
import { reactive } from '@vue/composition-api';
import Parse from 'parse';

export default {
  name: 'Home',
  setup () {
    let state = reactive({
      username: '',
      password: '',
      user: Parse.User.current(),
    });
    async function login () {
      state.user = await Parse.User.logIn(state.username, state.password);
    }
    async function logout () {
      await Parse.User.logOut();
      state.user = null;
    }
    return {
      state, login, logout
    };
  },
};
</script>
