// ref: https://stackoverflow.com/questions/3665115/how-to-create-a-file-in-memory-for-user-to-download-but-not-through-server
function triggerDownloadWithText (filename, text) {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

// ref: https://humanwhocodes.com/snippets/2019/05/nodejs-read-stream-promise/
function readStream (stream, encoding = 'utf8') {
  stream.setEncoding(encoding);
  return new Promise((resolve, reject) => {
    let data = '';
    stream.on('data', chunk => { data += chunk; });
    stream.on('end', () => resolve(data));
    stream.on('error', error => {
      reject(error);
    });
  });
}

module.exports = {
  triggerDownloadWithText,
  readStream,
};
