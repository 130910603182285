<template>
  <div id="app">
    <b-navbar>
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="img/logo.jpg" alt="Board Game Port Logo">
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-dropdown v-for="m of state.menu" :key="m.label" :label="m.label">
          <b-navbar-item v-for="item of m.items" :key="item.title" :href="item.href">
            {{ item.title }}
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>
      <template #end>
        <b-navbar-item href="https://www.youtube.com/channel/UCOLemwSfpB8uC7mtAhJT2Yw" target="_blank">
          <b-icon
            icon="youtube"
            size="is-medium">
          </b-icon>
        </b-navbar-item>
        <b-navbar-item href="https://www.facebook.com/BGPort/" target="_blank">
          <b-icon
            icon="facebook"
            size="is-medium">
          </b-icon>
        </b-navbar-item>
        <b-navbar-item href="https://www.patreon.com/bgport" target="_blank">
          <b-icon
            icon="patreon"
            size="is-medium">
          </b-icon>
        </b-navbar-item>
        <b-navbar-item href="mailto:bgporthk@gmail.com" target="_blank">
          <b-icon
            icon="email"
            size="is-medium">
          </b-icon>
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class='contentWrapper'>
      <router-view/>
    </div>
    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          桌遊港 2016-{{state.year}} 著作權所有.
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import { reactive, onErrorCaptured } from '@vue/composition-api';
import { DialogProgrammatic as Dialog } from 'buefy';
import { Parse } from 'parse/lib/browser/Parse';
import { registerErrorHandler, handleError } from './libs/vp-helper';

export default {
  setup (props, { root: vue }) {
    const state = reactive({
      menu: [],
      year: new Date().getFullYear(),
    });
    registerErrorHandler(e => {
      Dialog.alert({
        title: 'Error',
        message: e.message,
        hasIcon: true,
        icon: 'alert',
        ariaModal: true,
      });
    });
    onErrorCaptured((error, component, info) => {
      handleError(error);
    });
    (async () => {
      const config = await Parse.Config.get();
      const menuData = config.get('menu');
      for (const md of menuData) {
        state.menu.push(md);
      }
    })();
    return { state };
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  background: white;
  min-height: 600px;

  .contentWrapper {
    padding: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  :root {
    background: whitesmoke;
  }
  #app {
    max-width: 800px;
    margin: 2rem auto;
  }
}

</style>

<style>
/* override buefy */
.navbar-brand .navbar-item img {
  max-height: 3rem;
  border-radius: 1.5rem;
}
body p {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
body h1 {
  font-size: 2em;
  font-weight: bold;
}
body h2 {
  font-size: 1.5em;
  font-weight: bold;
}
</style>
