import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import Parse from 'parse';
import Buefy from 'buefy';

import 'buefy/dist/buefy.css';

Vue.config.productionTip = false;
Vue.use(VueCompositionAPI);

Vue.use(Buefy);

Parse.serverURL = process.env.VUE_APP_PARSE_SERVER_URL;
Parse.initialize(process.env.VUE_APP_PARSE_APP_ID);

if (process.env.NODE_ENV === 'development') {
  console.warn(process.env.VUE_APP_PARSE_APP_ID, process.env.VUE_APP_PARSE_SERVER_URL);
  window.Parse = Parse;
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
