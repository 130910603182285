const Parse = require('parse');
const maxCount = 1000;

async function destroyAllObjects (query, useMasterKey) {
  if (useMasterKey === undefined) useMasterKey = true;
  let count = 0;
  let result;
  query.select('objectId').limit(maxCount);
  do {
    result = await query.find({ useMasterKey: true });
    if (result.length > 0) {
      count += result.length;
      await Parse.Object.destroyAll(result, { useMasterKey });
    }
  } while (result.length > 0);
  return count;
}

async function queryAllObjects (query, callback, useMasterKey) {
  if (useMasterKey === undefined) useMasterKey = true;
  query.limit(maxCount);
  let skip = 0;
  let result;
  do {
    result = await query.skip(skip).find({ useMasterKey });
    if (result.length > 0) await callback(result);
    skip += maxCount;
  } while (result.length === maxCount);
}

async function queryManyObjects (query, callback, options = {}) {
  let { useMasterKey, limit = Infinity } = options;
  if (useMasterKey === undefined) useMasterKey = true;
  let skip = 0;
  let count = 0;
  let result;
  do {
    query.limit(Math.min(maxCount, limit - count));
    result = await query.skip(skip).find({ useMasterKey });
    if (result.length > 0) await callback(result);
    skip += maxCount;
    count += result.length;
  } while (result.length === maxCount && count < limit);
}

// To convert Parse Object to JSON data with all value
// Recursive processing would occur for pointer
// "processedData" is internally used to check recursive referrencing
function getParseObjectData (parseObject) {
  return _checkValue(parseObject, {});
}
function _getParseObjectData (parseObject, processedData) {
  if (parseObject === null) return null;
  if (processedData[parseObject.id]) return processedData[parseObject.id];
  let data = { objectId: parseObject.id };
  processedData[parseObject.id] = data;
  let keys = Object.keys(parseObject.attributes);
  for (let i = 0; i < keys.length; ++i) {
    let key = keys[i];
    let value = parseObject.get(key);
    value = _checkValue(value, processedData);
    data[key] = value;
  }
  data._parseObject = parseObject;
  return data;
}
function _checkValue (value, processedData) {
  if (!value) return value;
  if (Array.isArray(value)) {
    value = value.map(v => _checkValue(v, processedData));
  } else if (value.attributes) {
    value = _getParseObjectData(value, processedData);
  }
  return value;
}

module.exports = {
  destroyAllObjects,
  queryAllObjects,
  queryManyObjects,
  getParseObjectData,
};
