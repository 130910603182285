import Parse from 'parse';
import { watchEffect as _watchEffect, watch as _watch } from '@vue/composition-api';

const ErrorHandlers = [];

export async function handleError (e) {
  console.error(e);
  if (e.message === 'Invalid session token') {
    // logout and go login page
    Parse.User.become(null);
    window.location = '/#/login';
    return;
  } else if (e.message === 'Login is required') {
    // go login page
    window.location = '/#/login';
    return;
  }
  for (let handler of ErrorHandlers) {
    handler(e);
  }
}

export function registerErrorHandler (handler) {
  ErrorHandlers.push(handler);
}

export function watchEffect (cb) {
  _watchEffect(() => cb().catch(handleError));
}

export function watch (source, cb) {
  _watch(source, () => cb().catch(handleError));
}

export function requireLogin () {
  if (!Parse.User.current()) {
    throw new Error('Login is required');
  }
}
