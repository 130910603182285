<template>
  <div class="voting-2020">
    <b-loading :is-full-page="true" v-model="state.isLoading" :can-cancel="false"></b-loading>
    <div>
      <img style="width: 100%; max-width: 1000px" src="/img/peoplechoiceaward2020-youtube-01.jpg">
      <h1 class="title has-text-centered">{{state.title}}</h1>
      <div class="voteDescription" v-html="state.description"></div>
      <div v-if="state.isExpired">
        <b-button
          size="is-medium"
          v-for="config in state.voteConfigs"
          :key="config.tag"
          @click="$router.push(`voting${config.tag}`)" expanded
        >
          {{config.title}}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from '@vue/composition-api';
import Parse from 'parse';
import { getParseObjectData } from '../libs/parse-util';
import { handleError } from '../libs/vp-helper';
// function generateContent () {
//   // TODO: this is to generate content only
//   // const arr = [{"logo":"A01.png","link":"http://broadwaygames.com.hk"},{"logo":"A02.png","link":"https://shop.capstone.hk"},{"logo":"A03.jpeg","link":"https://www.jollythinkers.com"},{"logo":"A04.jpeg","link":"https://www.time2play.com.hk"},{"logo":"A05.jpeg","link":"http://www.base23.com"},{"logo":"A06.jpeg","link":"https://www.bgwalker.com"},{"logo":"A07.webp","link":"https://wobgames.net"},{"logo":"A09.png","link":"https://monstergeekbg.com"},{"logo":"A11.jpeg","link":"https://www.bgif.co"},{"logo":"A12.png","link":"https://www.facebook.com/wob.unplug"},{"logo":"A13.png","link":"https://goodmovebg.com"},{"logo":"A15.png","link":"https://www.allonboardhk.com"},{"logo":"A16.png","link":"https://gametable.hk"},{"logo":"A20.png","link":"https://whateverboardgame.com"},{"logo":"A22.jpeg","link":"https://www.facebook.com/partyideas.hk"},{"logo":"A27.jpg","link":"https://daydreamseekers.boutir.com"},{"logo":"A28.png","link":"https://www.icemakesboardgame.com"}];
//   const arr = [{"logo":"A08.png","link":"https://www.facebook.com/808BoardGame"},{"logo":"A10.png","link":"https://www.facebook.com/funcrazyhk"},{"logo":"A19.png","link":"https://www.facebook.com/HashTagMrBoard"},{"logo":"A24.jpeg","link":"https://www.facebook.com/keysworksbg"},{"logo":"A26.jpeg","link":"https://www.facebook.com/distinct.flower/"},{"logo":"B01.png","link":"https://www.facebook.com/BGSyndrome"},{"logo":"B02.png","link":"https://www.facebook.com/柴娃娃研究室-1557644981214480"},{"logo":"B04.png","link":"https://www.facebook.com/Fanbaplay"},{"logo":"B05.jpeg","link":"https://www.facebook.com/sonnyboardgame"},{"logo":"B07.jpeg","link":"https://www.facebook.com/boardgame.record"},{"logo":"B11.jpg","link":"https://www.facebook.com/rockrockdocker"},{"logo":"B14.jpeg","link":"https://www.facebook.com/hkbgdesign"},{"logo":"B06.jpg","link":"https://www.facebook.com/ptboardf"},{"logo":"A17.png","link":"https://www.pob.hk"},{"logo":"C01.jpg","link":"https://www.facebook.com/lifeisbg/"},{"logo":"C02.jpg","link":"https://www.facebook.com/Agametime"},{"logo":"C03.jpg","link":"https://www.jiugagames.com"}];
//   const content = arr.map(d => `<div style="width: 20%;"><a href="${d.link}" target="_blank"><div style="width: 100%; height: 100%; display: flex; align-items: center;"><img src="/img/2020/${d.logo}"></div></a></div>`);
//   console.log(content.join('\n'));
// }
// generateContent();
export default {
  setup () {
    let state = reactive({
      isLoading: true,
      voteConfigs: [],
      title: '',
      description: '',
      isExpired: false,
    });
    // setup with async ops
    (async () => {
      try {
        const voteConfigs = await new Parse.Query('VoteConfig').containedIn('tag', ['2020', '2020top5', '2020chin']).find();
        state.voteConfigs = getParseObjectData(voteConfigs.filter(c => c.get('tag') !== '2020'));
        const voteConfig = voteConfigs.find(c => c.get('tag') === '2020');
        state.isExpired = voteConfig.get('endDate') > new Date();
        state.title = voteConfig.get('title');
        state.description = voteConfig.get('description');
      } catch (e) {
        handleError(e);
      }
      state.isLoading = false;
    })();
    return { state };
  }
};
</script>
