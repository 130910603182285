<template>
  <div>
    <b-loading :is-full-page="true" v-model="state.isLoading" :can-cancel="false"></b-loading>
    <div v-html="state.page.content">
    </div>
  </div>
</template>

<script>
import { reactive, watch } from '@vue/composition-api';
import Parse from 'parse';
import { getParseObjectData } from '../libs/parse-util';
import { handleError } from '../libs/vp-helper';
export default {
  setup (props, { root: vue }) {
    let state = reactive({
      isLoading: true,
      page: {
        content: '',
      },
    });
    const fetchPageContent = async () => {
      state.isLoading = true;
      try {
        const pageKey = vue.$route.params.params;
        if (!pageKey) throw new Error('No page key');
        const page = await new Parse.Query('StaticPage').equalTo('key', pageKey).first();
        if (!page) throw new Error('No such page');
        state.page = getParseObjectData(page);
      } catch (e) {
        handleError(e);
        vue.$router.push('/');
      }
      state.isLoading = false;
    };
    watch(() => vue.$route.params.params, fetchPageContent);
    // setup with async ops
    (async () => {
      try {
        await fetchPageContent();
      } catch (e) {
        handleError(e);
        vue.$router.push('/');
      }
      state.isLoading = false;
    })();
    return { state };
  }
};
</script>

<style scoped>

</style>
