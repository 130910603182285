<template>
  <div class="admin">
    <b-loading :is-full-page="true" v-model="state.isLoading" :can-cancel="false"></b-loading>
    <h1 class="title">Voting Result</h1>
    <div v-for="vote of state.voteConfigs" :key="vote.tag">
      {{vote.title}}
      <b-button @click="exportCsv(vote)">
        CSV
      </b-button>
    </div>
  </div>
</template>

<script>
import { reactive } from '@vue/composition-api';
import Parse from 'parse';
import { getParseObjectData } from '../libs/parse-util';
import { triggerDownloadWithText } from '../libs/util';
import { handleError } from '../libs/vp-helper';

export default {
  setup (props, { root: vue }) {
    const state = reactive({
      isLoading: true,
      voteConfigs: [],
    });
    const exportCsv = async voteConfig => {
      const data = await Parse.Cloud.run('exportVotingData', { tag: voteConfig.tag });
      triggerDownloadWithText(`${voteConfig.tag}.csv`, data);
    };
    // setup with async ops
    (async () => {
      try {
        // check permission
        if (!Parse.User.current()) throw new Error('Access Denied');
        const roles = await Parse.Cloud.run('getRole');
        if (!roles.find(r => r.get('name') === 'admin')) throw new Error('Access Denied');
        // perpare for voting
        const voteConfigs = await new Parse.Query('VoteConfig').notEqualTo('hidden', true).find();
        state.voteConfigs = getParseObjectData(voteConfigs);
        //
        state.isLoading = false;
      } catch (e) {
        console.error(e);
        handleError(e);
        if (e.message === 'Access Denied') {
          vue.$router.push('/');
        }
      }
    })();
    return { state, exportCsv };
  },
};
</script>
