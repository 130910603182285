<template>
  <div class="voting-2020top5">
    <b-loading :is-full-page="true" v-model="state.isLoading" :can-cancel="false"></b-loading>
    <div v-if="state.state === 'intro'" v-show="!state.isLoading">
      <h1 class="title has-text-centered">{{state.title}}</h1>
      <div class="voteDescription" v-html="state.description"></div>
      <b-button type="is-success is-light" @click="state.state = 'vote'">開始投票</b-button>
    </div>
    <div v-if="state.state === 'vote'">
      <div class="is-flex is-justify-content-space-around">
        <div class="box form">
          <h1 class="title has-text-centered">{{state.title}}</h1>
          <label class="label">投票選擇（最多五款桌遊）</label>
          <div class="displayGameList columns is-flex-wrap-wrap">
            <div class="displayGame column is-full-mobile is-full-tablet is-half-desktop" v-for="game of displayingGames" :key="game.bggId">
              <div class="media">
                <b-checkbox v-model="voteForm.chosenGameIds" :native-value="game.bggId"></b-checkbox>
                <div class="media-left gameThumbnail" :style="{'backgroundImage': `url('${game.thumbnail}')`}"></div>
                <div class="media-content">
                  {{ game.name }}
                  <br>
                </div>
              </div>
            </div>
          </div>
          <b-field v-if="voteForm.chosenGameIds.length < 5">
            <b-autocomplete
              v-model="state.gameNameSearchString"
              :data="filteredGames"
              placeholder="在此可搜尋其他2020年推出的桌遊"
              icon="magnify"
              field="name"
              open-on-focus
              @select="selectGame">
              <template slot-scope="{option: game}">
                <div class="media">
                  <div class="media-left gameThumbnail" :style="{'backgroundImage': `url('${game.thumbnail}')`}"></div>
                  <div class="media-content">
                    {{ game.name }}
                    <br>
                    <small>
                      Rank: {{ game.rank }},
                      Rating: {{ game.rating }}
                    </small>
                  </div>
                </div>
              </template>
            </b-autocomplete>
          </b-field>
          <b-field label="身處地區">
            <b-radio v-model="voteForm.location" native-value="hk">香港</b-radio>
            <b-radio v-model="voteForm.location" native-value="tw">台灣</b-radio>
            <b-radio v-model="voteForm.location" native-value="other">其他</b-radio>
          </b-field>
          <b-field label="是否已加入 桌遊港Patreon?">
            <b-radio v-model="voteForm.patreon" native-value="false">否</b-radio>
            <b-radio v-model="voteForm.patreon" native-value="true">是</b-radio>
          </b-field>
          <b-field label="電郵地址 (如已加入 桌遊港Patreon 請填入 Patreon 帳戶電郵)">
            <b-input type="email" v-model="voteForm.email"></b-input>
          </b-field>
          <b-field label="電話號碼尾4個數字：(電話號碼只作領獎時核實身份之用；此欄可以不填，則視作放棄參加獎品活動)">
            <b-input v-model="voteForm.phone"></b-input>
          </b-field>
          <div class="is-flex is-justify-content-flex-end">
            <b-button type="is-success is-light" @click="submit()">確認投票</b-button>
          </div>
        </div> <!-- end of div.box -->
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from '@vue/composition-api';
import { DialogProgrammatic as Dialog } from 'buefy';
import * as _ from 'lodash';
import Parse from 'parse';
import { getParseObjectData } from '../libs/parse-util';
import { handleError } from '../libs/vp-helper';
export default {
  setup () {
    let state = reactive({
      isLoading: true,
      state: 'intro',
      games: [],
      gameNameSearchString: '',
      displayingGameIds: [],
      title: '',
      description: '',
    });
    let voteForm = reactive({
      email: '',
      patreon: 'false',
      location: 'hk',
      chosenGameIds: [],
    });
    // TODO: read from server / params
    const voteTag = '2020top5';
    const displayingGames = computed(() => {
      return state.games.filter(g => state.displayingGameIds.includes(g.bggId) || voteForm.chosenGameIds.includes(g.bggId));
    });
    function selectGame (game) {
      if (!game) return;
      if (!voteForm.chosenGameIds.includes(game.bggId)) {
        voteForm.chosenGameIds.push(game.bggId);
      }
      setTimeout(() => {
        state.gameNameSearchString = '';
      }, 100);
    }
    const filteredGames = computed(() => {
      if (!state.gameNameSearchString) return state.games;
      return state.games.filter(g => g.name.toLowerCase().startsWith(state.gameNameSearchString.toLowerCase()));
    });
    async function submit () {
      // TODO:
      voteForm.patreon = voteForm.patreon === 'true';
      console.log(voteForm);
      state.isLoading = true;
      try {
        // TODO: validation
        if (!voteForm.email) throw new Error('請填上電郵地址');
        if (!voteForm.chosenGameIds.length) throw new Error('請選擇遊戲');
        if (voteForm.chosenGameIds.length > 5) throw new Error('最多選擇五款遊戲');
        await Parse.Cloud.run('vote', { tag: voteTag, data: voteForm });
        Dialog.alert({
          message: '投票完成！',
          hasIcon: false,
          ariaModal: true
        });
        this.$router.push('/');
      } catch (e) {
        handleError(e);
      }
      state.isLoading = false;
    };
    // setup with async ops
    (async () => {
      try {
        // get vote configs
        // const config = await Parse.Config.get();
        // state.displayingGameIds = config.get(`vote${voteTag}_displayingGameIds`);
        const voteConfig = await new Parse.Query('VoteConfig').equalTo('tag', voteTag).first();
        state.displayingGameIds = voteConfig.get('displayingGameIds');
        state.title = voteConfig.get('title');
        state.description = voteConfig.get('description');
        // get game lists
        const gameObjs = await new Parse.Query('BggGame').select(['bggId', 'name', 'thumbnail', 'rank', 'rating']).equalTo('isExpansion', false).addDescending('rating').limit(1000).find();
        state.games = getParseObjectData(gameObjs);
        if (!state.description) {
          // jump to voting if there is no description
          state.state = 'vote';
        }
        state.isLoading = false;
      } catch (e) {
        handleError(e);
      }
    })();
    return { state, voteForm, displayingGames, selectGame, filteredGames, submit };
  }
};
</script>

<style lang="scss" scoped>
.voteDescription {
  margin-bottom: 1rem;
}

.form {
  width: 36rem;

  .field {
    margin-bottom: 2rem;
  }
}

.displayGameList {
  margin-top: 1rem;
}

.displayGame {
  height: 3rem;
  overflow: hidden;
  padding: 0 0.75em;
}

.gameThumbnail {
  width: 42px;
  height: 42px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
