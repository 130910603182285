<template>
  <div class="home">
    <div class="row">
      <img style="width: 100%; max-width: 1000px" src="/img/channels4_banner.jpg">
    </div>
    <div class="row">
    </div>
  </div>
</template>

<script>
import { Parse } from 'parse/lib/browser/Parse';

export default {
  name: 'Home',
  setup (props, { root: vue }) {
    (async () => {
      const config = await Parse.Config.get();
      // TODO: tempory redirect to target page
      const redirect = config.get('mainRedirect');
      console.warn('redirect', redirect);
      if (redirect) {
        vue.$router.push(redirect);
      }
    })();
  },
};
</script>
