import Vue from 'vue';
import VueRouter from 'vue-router';
const path = require('path');

Vue.use(VueRouter);

let routes = [];
// read pages
let pages = {};
let context = require.context('../pages', true, /\.vue$/);
context.keys().forEach(function (key) {
  let pageName = path.basename(key, '.vue');
  var page = context(key).default;
  pages[pageName] = page;
  var route = pageName.toLowerCase();
  routes.push({
    path: `/${route}/:params*`,
    component: page
  });
});
// default route to index
routes.push({
  path: '/',
  component: pages.Main
});
// other route to main
routes.push({
  path: '/:route?/:params*',
  component: pages.Main
});

const router = new VueRouter({
  routes
});

export default router;
